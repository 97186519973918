<template>
  <div class="saerchPublic">
    <div class="search flex">
      <div class="searchForm flex">
        <el-select
          v-model="$store.state.searchParam.searchField"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div style="width: 1px; height: 22px; background: #0188ef">|</div>
        <el-input
          clearable
          maxlength="30"
          @keyup.enter.native="toSearch"
          v-model="searchWord"
          placeholder="请输入要检索的内容"
        ></el-input>
        <img @click="toSearch" src="../assets/images/icon-search.png" />
      </div>
      <img
        class="heightSearch"
        @click="toHighSearch"
        src="../assets/images/btn-gjjs-default.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom",
  data() {
    return {
      searchWord: "",
      options: [
        {
          value: "title,author",
          label: "全部",
        },
        {
          value: "title",
          label: "书名",
        },
        {
          value: "author",
          label: "作者",
        },
        {
          value: "isbn",
          label: "ISBN",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toSearch() {
      if (this.searchWord.trim().length == 0) {
        this.$message.warning("请输入检索词");
        return;
      }
      let searchWord = this.searchWord;
      this.$store.state.searchParam.searchWord = searchWord;
      this.searchWord = "";
      if (this.$route.path == "/classify") {
        // location.reload()
        this.$emit("search", searchWord);
      } else if (this.$route.path == "/search") {
        this.$emit("search", searchWord);
      } else {
        this.$router.push({ path: "/classify", query: { searchWord } });
      }
    },
    toHighSearch() {
      this.$router.push({ path: "/highSearch" });
    },
  },
};
</script>

<style lang="scss" scoped>
.saerchPublic {
  height: 140px;
  width: 100%;
  background: url("../assets/images/bj-neiye.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .search {
    align-items: center;
    justify-content: center;
    .searchForm {
      width: 640px;
      background: #fff;
      border: 4px solid #a8cdff;
      box-shadow: -6px 0px 39.48px 0.36px rgba(0, 98, 173, 0.5);
      border-radius: 40px;
      align-items: center;
      padding: 6px 0;
      margin-right: 10px;
      .el-select {
        margin-left: 20px;
        ::v-deep {
          .el-input__inner {
            border: none;
            width: 100px;
          }
        }
      }
      ::v-deep .el-input {
        flex: 1;
        .el-input__inner {
          border: none;
        }
      }
      img {
        margin-right: 30px;
        cursor: pointer;
      }
    }
    .heightSearch {
      cursor: pointer;
    }
  }
}
</style>
