<template>
  <div class="highSearch">
    <SearchTop></SearchTop>
    <div class="wapper">
      <div class="subjectItem">
        <el-row>
          <el-col :span="11">
            <span class="w150">学科分类：</span>
            <el-cascader
              style="width: 260px"
              ref="cascader"
              @change="getSubName"
              v-model="subjectIds"
              :options="classifyList"
              :props="{
                emitPath: false,
                checkStrictly: true,
                label: 'name',
                value: 'id',
              }"
              clearable
            ></el-cascader>
          </el-col>
          <el-col :span="13"
            ><span style="width: 110px" class="w150">出版时间：</span>
            <el-date-picker
              v-model="publishYearMonthStart"
              style="width: 130px"
              type="month"
              placeholder="开始时间"
              format="yyyy-MM"
              value-format="yyyy-MM"
            >
            </el-date-picker>
            <div
              style="
                width: 10px;
                height: 2px;
                background: #999999;
                display: inline-block;
                margin-left: 13px;
                margin-right: 13px;
                vertical-align: middle;
              "
            ></div>
            <el-date-picker
              format="yyyy-MM"
              value-format="yyyy-MM"
              v-model="publishYearMontEnd"
              style="width: 130px"
              type="month"
              placeholder="结束时间"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
      <div class="item flex">
        <div class="w150">检索关系</div>
        <div class="w150">检索字段</div>
        <div class="w400">检索词</div>
      </div>
      <div class="item flex" v-for="(formitem, index) in form" :key="index">
        <div>
          <el-select v-model="formitem.andOr" placeholder="请选择">
            <el-option
              v-for="item in relations"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="formitem.searchField" placeholder="请选择">
            <el-option
              v-for="item in fields"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="w400">
          <el-input
            clearable
            maxlength="30"
            v-model="formitem.searchWord"
            placeholder="请输入"
          ></el-input>
        </div>
        <div>
          <el-select v-model="formitem.likeOrEqual" placeholder="请选择">
            <el-option
              v-for="item in searchs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <img
            class="mr15 pointer"
            @click="delForm(index)"
            v-if="form.length > 1"
            src="../../assets/images/btn-jianshao.png"
          />
          <img
            v-show="index == form.length - 1"
            class="pointer"
            @click="addForm(index)"
            src="../../assets/images/btn-zhenjia.png"
          />
        </div>
      </div>
      <div class="subjectItem">
        <span class="w150">结果排序方式：</span>
        <el-select v-model="sort" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="btn">
        <el-button @click="handleSearch" type="primary">检索</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchTop from "@/components/searchTop";
import { indexSubjectList } from "@/api/home.js";
import { handleTree } from "../../utils/cxstar";
export default {
  data() {
    return {
      subjectIds: "",
      classifyList: [],
      publishYearStart: null,
      publishYearEnd: null,
      publishYearMonthStart: "",
      publishYearMontEnd: "",
      options: [
        {
          id: "0",
          value: "state_time",
          label: "上架时间",
          sort: "desc",
          asc: false,
          desc: false,
        },
        {
          id: "1",
          value: "score",
          label: "相关度",
          sort: "desc",
          asc: false,
          desc: false,
        },
        {
          id: "4",
          value: "CONVERT(title using gbk)",
          label: "书名升序",
          sort: "asc",
          asc: true,
          desc: true,
        },
        {
          id: "5",
          value: "CONVERT(title using gbk)",
          label: "书名降序",
          sort: "desc",
          asc: true,
          desc: true,
        },
        {
          id: "2",
          value: "publish_date",
          label: "出版日期升序",
          sort: "asc",
          asc: true,
          desc: true,
        },
        {
          id: "3",
          value: "publish_date",
          label: "出版日期降序",
          sort: "desc",
          asc: true,
          desc: true,
        },
      ],
      relations: [
        {
          value: "and",
          label: "并且",
        },
        {
          value: "or",
          label: "或者",
        },
      ],
      searchs: [
        {
          value: "=",
          label: "精确",
        },
        {
          value: "like",
          label: "模糊",
        },
      ],
      fields: [
        {
          value: "title",
          label: "书名",
        },
        {
          value: "author",
          label: "作者",
        },
        {
          value: "isbn",
          label: "ISBN",
        },
      ],
      form: [
        {
          andOr: "and",
          searchWord: "",
          searchField: "title",
          likeOrEqual: "like",
        },
      ],
      sort: "0",
    };
  },
  components: {
    SearchTop,
  },
  created() {
    this.$store.dispatch("resetField");
    this.getData();
  },
  mounted() {},
  methods: {
    // 获取学科名称
    getSubName(item) {
      // let getCheckedNodes = this.$refs.cascader.getCheckedNodes()[0];
      // let names = getCheckedNodes.pathLabels.join(' > ')
      // this.$store.dispatch('setLeft',{'subjectIds':names})
    },
    // 高级检索
    handleSearch() {
      let queryForm = {
        pageNum: 1,
        pageSize: 10,
      };
      // 学科分类
      if (
        this.subjectIds != undefined &&
        this.subjectIds != null &&
        this.subjectIds != ""
      ) {
        queryForm.subjectIds = [this.subjectIds];
        let getCheckedNodes = this.$refs.cascader.getCheckedNodes()[0];
        let names = getCheckedNodes.pathLabels.join(" > ");
        this.$store.dispatch("setLeft", { subjectIds: `全部 > ${names}` });
      }
      // 出版年份
      if (this.publishYearMonthStart) {
        queryForm.publishYearMonthStart = this.publishYearMonthStart;
      }
      if (this.publishYearMontEnd) {
        queryForm.publishYearMontEnd = this.publishYearMontEnd;
      }
      // 排序
      if (this.sort != undefined && this.sort != null && this.sort != "") {
        const item = this.options.filter(item => {
          return item.id == this.sort;
        });
        queryForm.orderByColumn = item[0].value;
        // queryForm.isAsc=this.sort=='publish_date'?'desc':'asc';
        queryForm.isAsc = item[0].sort;
      }
      // 自定义条件
      let searchItemList = [];
      this.form.forEach(element => {
        if (element.searchWord.trim() != "") {
          searchItemList.push(element);
        }
      });
      this.$store.dispatch("setLeft", { searchItemList: searchItemList });
      queryForm.searchItemList = searchItemList;
      // let obj = {key:'subjectIds',value:[item.id]}
      this.$store.dispatch("setAllField", queryForm);
      this.$router.push({
        path: "/search",
      });
    },
    // 获取数据
    getData() {
      indexSubjectList().then(res => {
        let treeData = handleTree(res.data);
        this.classifyList = treeData;
      });
    },
    addForm(index) {
      if (this.form.length == 5) {
        this.$message.warning("检索条件最多五条");
        return;
      }
      this.form.push({
        andOr: "and",
        searchWord: "",
        searchField: "title",
        likeOrEqual: "like",
      });
    },
    delForm(index) {
      this.form.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  font-size: 16px !important;
  padding: 12px 40px;
}
.highSearch {
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 173px);
  .wapper {
    width: 1000px;
    margin: 0 auto;
    padding: 80px 0;
    img {
      display: block;
    }
    .subjectItem {
      margin-bottom: 40px;
      span {
        text-align: right;
        margin-right: 15px;
      }
      .el-select {
        width: 250px;
      }
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
    }
    .w400 {
      width: 400px !important;
      text-align: center;
      .el-select {
        width: 400px !important;
      }
    }
    .w150 {
      display: inline-block;
      width: 150px;
      text-align: center;
    }
    .item {
      align-items: center;
      margin-bottom: 20px;
      > div {
        margin-right: 15px;
      }
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
      .el-select {
        width: 150px;
        ::v-deep .el-input__inner {
          border-radius: 0;
        }
      }
    }
    .btn {
      text-align: center;
    }
  }
}
</style>
